<template>
  <div style="background: #efeeeb">
    <div v-if="config.cover" style="width: 100%;">
      <img style="width: 100%" :src="config.cover" alt="">
    </div>
    <div style="max-width: 1200px; padding: 20px; margin: 0 auto; text-align: center; line-height: 2; color: #999; font-size: 16px;" v-html="config.content"></div>
  </div>
</template>

<script>
import config from '../../configCenter.json'
export default {
  data () {
    return {
      config
    }
  },
  mounted() {
    document.title = config.title || config.companyFullName
  }
}
</script>

<style scoped>

</style>